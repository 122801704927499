/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "L'Appel du Coucou est le premier volet des enquêtes du détective privé Cormoran Strike."), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, "Une jeune étoile montante de la mode est retrouvée morte au beau milieu de la nuit dans un quartier chic de Londres. Le corps de Luna Landry gît dans la neige en bas de son immeuble.\nPendant plusieurs semaines, cette histoire tragique fait le tour des médias. Chaque détail est longuement repris par la presse et la TV. Rapidement, la police conclut à un suicide. La jeune femme se serait défenestrée."), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, "Quelques mois plus tard, la famille de la défunte embauche le détective privé pour reprendre l'enquête.\nLa vie de Cormoran est un champ de ruine. Cet ancien militaire handicapé depuis une mission en Afghanistan est endetté, il vient de se séparer de sa compagne et son agence ne fonctionne pas. Cette affaire semble être sa dernière chance de remonter la pente.\nAvec sa nouvelle assistante Robin, ils mettent donc tout en oeuvre pour tirer au clair cette histoire. Luna Landry s'est-elle suicidée cette nuit-là ? Cormoran va-t-il réussir à surmonter ses difficultés et résoudre ses problèmes personnels ? Cette affaire est elle la dernière avant la banqueroute de son agence ?"), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, "Ce livre est un très bon roman policier. En quelques pages, l'auteur nous dépeint des personnages complexes et attachants. On plonge rapidement dans l'enquête. Difficile de décrocher du livre avant la fin... Cette affaire annonce une très belle suite pour la série Cormoran Strike."), "\n", React.createElement("br"), "\n", React.createElement(_components.p, null, "Robert Galbraith est le nom d'emprunt de JK Rowling, auteur de Harry Potter."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
